<template>
  <div class="provision">
    <div class="provision_list">
      <p class="title">不氪套社区用户协议</p>
      <div class="content indent">
        <span>请您仔细阅读以下条款，如果您对本协议的任何条款表示异议，您可以选择不进入不氪套社区（以下简称不氪套）。</span>
        <span>当您登录成功，无论是进入不氪套，还是在不氪套上发布任何内容（即「内容」），均意味着您（即「用户」）完全接受本协议项下的全部条款。</span>
      </div>
      <p class="title">使用规则</p>
      <div class="content">
        <p>1.用户登录成功后，用户应当对以其用户帐号进行的所有活动和事件负法律责任。</p>
        <p>2.用户须对在不氪套发布信息的真实性、合法性、有效性承担全部责任，用户不得冒充他人；不得利用他人的名义发布任何信息；否则不氪套有权立即停止提供服务，收回其权限并由用户独自承担由此而产生的一切法律责任。</p>
        <p>3.不氪套是一个表达心声、观点、建议的平台，用户通过不氪套对任何信息的发表即认可该信息为公开的信息，并单独对此行为承担法律责任。</p>
        <p>4.用户承诺不得以任何方式利用不氪套直接或间接从事违反中国法律以及社会公德的行为，不氪套有权对违反上述承诺的内容予以删除。</p>
        <p>5. 为树立良好形象，规范社区平台运营，遵照集团《新闻发言人制度》，社区信息传播应遵守以下要求：</p>
        <p>（1）不造谣，不信谣，不传谣；</p>
        <p>（2）行使个人言论自由和权力的同时，不得损害国家、社会、集体利益和集团及其所属各单位的合法权利；</p>
        <p>（3）不得发表任何蕴含政治敏感、国别歧视、民族歧视、种族歧视、宗教歧视、性别歧视、生理残疾歧视、文化歧视、地域歧视、以及暴力倾向等其他内容；</p>
        <p>（4）不随意批评和恶意指点其他企业品牌，保持客观中立立场；</p>
        <p>（5）不得使用淫秽低俗、有碍观瞻并可能引发非议的视频、图片和文字；</p>
        <p>（6）不得侵犯他人的知识产权、肖像权等。</p>
        <p>6．用户不得利用不氪套服务制作、上载、复制、发布、传播或者转载如下内容：</p>
        <p>（1）反对宪法所确定的基本原则的；</p>
        <p>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
        <p>（3）损害国家荣誉和利益的；</p>
        <p>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
        <p>（5）侮辱、滥用英烈形象，否定英烈事迹，美化粉饰侵略战争行为的；</p>
        <p>（6）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
        <p>（7）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
        <p>（8）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
        <p>（9）侮辱或者诽谤他人，侵害他人合法权益的；</p>
        <p>（10）含有法律、行政法规禁止的其他内容的信息。</p>
        <p>7.不氪套有权对用户使用不氪套的情况进行审查和监督，如用户在使用不氪套时违反任何上述规定，不氪套或其授权的人有权要求用户改正或直接采取一切必要的措施（包括但不限于更改或删除用户张贴的内容、暂停或终止用户使用不氪套的权利）以减轻用户不当行为造成的影响。</p>
      </div>
      <p class="title">知识产权</p>
      <div class="content">
        <p>1.不氪套提供的网络服务中包含的标识、版面设计、排版方式、文本、图片、图形等均受著作权、商标权及其它法律保护，未经相关权利人（含不氪套及其他原始权利人）同意，上述内容均不得在任何平台被直接或间接发布、使用、出于发布或使用目的的改写或再发行，或被用于其他任何商业目的。 </p>
        <p>2.在不氪套上传或发表的内容，用户应保证其为著作权人或已取得合法授权，并且该内容不会侵犯任何第三方的合法权益。如果第三方提出关于著作权的异议，不氪套有权根据实际情况删除相关的内容，且有权追究用户的法律责任。给不氪套或任何第三方造成损失的，用户应负责全额赔偿。</p>
        <p>3.不氪套有权但无义务对用户发布的内容进行审核，有权根据相关证据结合法律法规及不氪套社区指导原则对侵权信息进行处理。</p>
      </div>
      <p class="title">个人隐私</p>
      <div class="content indent">
        尊重用户个人隐私信息的私有性是不氪套的一贯原则，不氪套将通过技术手段、强化内部管理等办法充分保护用户的个人隐私信息，
        除法律或有法律赋予权限的政府部门要求或事先得到用户明确授权等原因外，不氪套保证不对外公开或向第三方透露用户个人隐私信息，
        或用户在使用服务时存储的非公开内容。同时，为了运营和改善不氪套的技术与服务，不氪套将可能会自行收集使用或向第三方提供用户的非个人隐私信息，这将有助于不氪套向用户提供更好的用户体验和服务质量。
      </div>
      <p class="title">免责声明</p>
      <div class="content">
        <p>1.不氪套不能对用户发表的回答或评论的正确性进行保证。</p>
        <p>2.用户在不氪套发表的内容仅表明其个人的立场和观点，并不代表不氪套的立场或观点。作为内容的发表者，需自行对所发表内容负责，
        因所发表内容引发的一切纠纷，由该内容的发表者承担全部法律及连带责任。不氪套不承担任何法律及连带责任。</p>
      </div>
      <p class="title">协议修改</p>
      <div class="content indent">
        根据互联网的发展和有关法律、法规及规范性文件的变化，或者因业务发展需要，不氪套有权对本协议的条款作出修改或变更，一旦本协议的内容发生变动，不氪套将会直接在不氪套上公布修改之后的协议内容，该公布行为视为不氪套已经通知用户修改内容。不氪套也可采用电子邮件或私信的传送方式，提示用户协议条款的修改、服务变更、或其它重要事项。
        如果不同意不氪套对本协议相关条款所做的修改，用户有权并应当停止使用不氪套。如果用户继续使用不氪套，则视为用户接受不氪套对本协议相关条款所做的修改。
      </div>
    </div>
    <div class="operate">
      <div @click="handleCancle" class="cancle">拒绝</div>
      <div @click="handleConfirm" class="confirm">同意</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {
    handleCancle() {
      this.$emit('cancle')
    },
    handleConfirm() {
      this.$emit('confirm')
    }
  },
};
</script>

<style lang='scss' scoped>
.provision {
  font-size: 14px;

  .title {
    font-weight: bold;
    font-size: 15px;
  }
  p {
    margin: 5px 0;
  }
  .indent {
    text-indent: 1em;
  }
  .operate {
    display: flex;
    justify-content: flex-end;
    position: sticky;
    bottom: -20px;
    left: 0;
    right: 0;
    background-color: #fff;
    overflow-y: auto;
    padding: 10px 0;

    .cancle {
      color: $remarkColor;
    }
    .confirm {
      color: $mainColor;
      margin: 0 20px;
    }
  }
}
</style>
